<!--
  PACKAGE_NAME : src\pages\esp\system\holiday\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 공휴일 관리
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="onInitNewRow" />
  </div>
</template>

<script>
import { isSuccess, getPastFromToday } from '@/plugins/common-lib';
import moment from 'moment';
import CustomStore from 'devextreme/data/custom_store';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: { EspDxDataGrid },
  data() {
    return {
      dataGrid: {
        id: 'holidayId',
        refName: 'holidayGrid',
        apiActionNm: {
          merge: 'HOLIDAY_LIST_SAVE',
        },
        dataSource: [],
        showActionButtons: {
          delete: false, // 기존 삭제 버튼 제거
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '삭제',
                elementAttr: { class: 'btn_XS white light_filled trash' },
                width: 60,
                height: 30,
                onClick: () => {
                  this.onDeleteData();
                },
              },
              location: 'before',
            },
          ], // 그리드 커스텀 버튼 생성
        },
        customEvent: {
          initNewRow: true,
        },
        columns: [
          {
            caption: '유형',
            dataField: 'holidayTypeCd',
            allowHeaderFiltering: false,
            lookup: {
              dataSource: this.$_enums.common.holidayType.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
            requiredRule: {
              message: '유형은 필수입니다.',
            },
          },
          {
            caption: '공휴일명',
            dataField: 'holidayNm',
            alignment: 'center',
            allowHeaderFiltering: false,
            allowGrouping: false,
            requiredRule: {
              message: '공휴일명은 필수입니다.',
            },
          },
          {
            caption: '일자',
            dataField: 'holidayTm',
            dataType: 'date',
            format: 'yyyy.MM.dd',
            cellTemplate: (container, options) => {
              const dateValue = moment(options.value, 'YYYYMMDD');
              const condition = options.data.holidayTypeCd === this.$_enums.common.holidayType.FIXED.value;
              const formattedDate = condition ? dateValue.format('MM.DD') : dateValue.format('YYYY.MM.DD');
              container.append(formattedDate);
            },
            allowHeaderFiltering: false,
            requiredRule: {
              message: '일자는 필수입니다.',
            },
          },
          {
            caption: '음력 여부',
            dataField: 'lunarTypeFl',
            allowHeaderFiltering: false,
            allowGrouping: false,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
          {
            caption: '등록자',
            i18n: 'COMPONENTS.REGISTRANT',
            dataField: 'regId',
            allowEditing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: '등록일시',
            i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
            dataField: 'regDt',
            dataType: 'date',
            format: 'yyyy-MM-dd HH:mm:ss',
            allowEditing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: '수정자',
            i18n: 'COMPONENTS.MODIFIER',
            dataField: 'editId',
            allowEditing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: '수정 일시',
            i18n: 'COMPONENTS.MODIFY_DATE_TIME',
            dataType: 'date',
            dataField: 'editDt',
            format: 'yyyy-MM-dd HH:mm:ss',
            allowEditing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
        ],
      },
    };
  },
  methods: {
    /** @description: 그리드 새 행 초기 셋팅 이벤트 */
    onInitNewRow(e) {
      e.data.holidayTm = getPastFromToday(0, 'days');
      e.data.lunarTypeFl = this.$_enums.common.stringUsedFlag.NO.value;
    },
    /**
     * @description: 공휴일 조회
     * @returns {{data, totalCount}}
     */
    selectDataList() {
      const vm = this;
      this.dataGrid.dataSource = new CustomStore({
        key: 'holidayId',
        async load(loadOptions) {
          const params = vm.$_getDxDataGridParam(loadOptions);
          const payload = {
            actionname: 'HOLIDAY_LIST_ALL',
            data: { params },
            useErrorPopup: true,
          };

          let rtnData = {
            data: [],
            totalCount: 0,
          };
          const res = await vm.CALL_API(payload);
          if (isSuccess(res)) {
            rtnData = {
              data: res.data.data,
              totalCount: res.data.header.totalCount,
            };
          }
          return rtnData;
        },
        insert: values => {
          return new Promise((resolve, reject) => {
            resolve();
          });
        },
        update: (key, values) => {
          return new Promise((resolve, reject) => {
            resolve();
          });
        },
        remove: key => {
          return new Promise((resolve, reject) => {
            resolve();
          });
        },
        totalCount: loadOptions => {
          return new Promise((resolve, reject) => {
            resolve(0); // 임시로 0 반환
          });
        },
      });
    },
    /**
     * @description: 공휴일관리 삭제
     * @returns {Promise<void>}
     */
    async onDeleteData() {
      const selectedRowsData = this.$refs.holidayGrid.getGridInstance.getSelectedRowsData();
      if (selectedRowsData?.length) {
        if (await this.$_Confirm(this.$_msgContents('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          const payload = {
            actionname: 'HOLIDAY_LIST_DELETE',
            data: {
              data: selectedRowsData.map(item => item.holidayId),
            },
            useErrorPopup: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$refs.holidayGrid.refreshData();
          }
        }
      } else {
        this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
      }
    },
  },
  mounted() {
    this.selectDataList();
  },
};
</script>

<style scoped>
.sub_new_style01 .page_search_box .inner div {
  display: inline-block;
}

.sub_new_style01 .page_search_box .inner > div {
  vertical-align: middle;
  margin-right: 10px;
}
</style>
